import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import Formsy from 'formsy-react';
import { Typography, Grid, Button, CircularProgress, InputAdornment, SnackbarContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TextInput from 'components/inputs/TextInput';
import CheckboxInput from 'components/inputs/CheckboxInput';
import RadioInput from 'components/inputs/RadioInput';
import DateInput from 'components/inputs/DateInput';
import moment from 'moment';
import { addToCart } from 'actions/CartActions';
import { currencyCode, currencySign } from 'utils/locales';
import { purchaseDisplay } from 'utils/promotions';
import renderHTML from 'react-render-html';
import { commonTheme } from 'utils/commonStyles';
import Expandable from 'components/Expandable';
import InfoIcon from '@material-ui/icons/Info';
import Autolinker from 'autolinker';
import { viewContent } from 'utils/tracking';
import { appendSeoBrand } from 'utils/seo';
import { FormattedMessage, FormattedNumber, FormattedDate, injectIntl } from "react-intl";

const presetValues = [50, 100, 200];
const promotionStyle = {
  color: window.PROMOTION_FONT_COLOUR,
  background: window.PROMOTION_BACKGROUND_COLOUR,
  padding: '0.75em'
};

export class OrderCardForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
      cardDesign: null,
      cardType: null,
      includeMessage: false,
      sendNow: '1'
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateAmount = this.updateAmount.bind(this);
  }

  updateAmount(evt) {
    const { cardDesign } = this.state;
    const value = parseFloat(evt.target.value);

    if (value >= cardDesign.min_amount && value <= cardDesign.max_amount) {
      this.setState({ value: value });
    }
  }

  componentDidMount() {
    if (this.props.products.length > 0) {
      this.loadCardDesign();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.products !== prevProps.products) {
      this.loadCardDesign();
    }
  }

  loadCardDesign() {
    const { products, match, navigateTo } = this.props;

    const designSlug = match.params.cardDesignSlug;
    const cardDesign = _.find(products, function(c) { return c.slug == designSlug || c.id == designSlug });
    if (!cardDesign) {
      navigateTo('/');
      return;
    }
    var location = this.props.location || {};
    var state = location.state || {};
    var type = cardDesign.product_type == 'physical' ? 'physical' : 'digital';
    var defaultValue = cardDesign.product_type == 'digital_retailer' && cardDesign.denominations.length == 1 ? cardDesign.denominations[0] : cardDesign.min_amount;
    this.setState({cardDesign, value: parseFloat(defaultValue).toFixed(defaultValue % 1 === 0 ? 0 : 2), cardType: type});
    viewContent(cardDesign.name);
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    const { cardDesign } = this.state;
    const { addToCart, siteId } = this.props;
    addToCart(_.merge(data, {site_id: siteId, card_design_id: cardDesign.id, promotion_id: cardDesign.promotion?.id}));
  }

  render() {
    const { classes, max_card_quantity, digital_card_send_hour, isProcessing, navigateTo, intl } = this.props;
    const { cardDesign, cardType, sendNow } = this.state;

    if (!cardDesign) {
      return <center><CircularProgress color="primary" size={50} /></center>
    }

    const minCardValue = cardDesign.min_amount;
    const maxCardValue = cardDesign.max_amount;
    const denominations = cardDesign.product_type == 'digital_retailer' ? cardDesign.denominations.map(d => {return Number(d)}).sort((x,y)=> x-y) : presetValues;
    const requiredValidation= { isDefaultRequiredValue: intl.formatMessage({id: "validation_required", defaultMessage: "can't be blank"})};

    return (
      <React.Fragment>
        <Helmet>
          <title>{appendSeoBrand(cardDesign.page_title)}</title>
          <meta name="description" content={cardDesign.description ? cardDesign.description.substring(0, 150) : `Purchase a ${cardDesign.name} gift card today`} />
          <meta name="robots" content={window.APP_ENV === 'production' ? 'index' : 'noindex'} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <section className="single-product-container grid-outer">
          <article className="single-product grid medium">
            <div className="product-info">
              <div className="product-thumb">
                <img src={cardDesign.image} alt={cardDesign.name} />
              </div>

              <div className="product-description">
                <Typography variant="h5" component="h1" className="top-space bottom-space">{cardDesign.name}</Typography>
                {cardDesign.description &&
                  <Typography component="span" color="textPrimary" paragraph>
                    {renderHTML(cardDesign.description)}
                  </Typography>
                }
              </div>
            </div>

            <div className="product-form">
              <Formsy onValidSubmit={this.handleSubmit} className={classes.form} style={{marginBottom: '2em'}}>
                <Grid container spacing={8} justify='center'>
                  <Grid item xs={12} md={12}>
                    <TextInput type="hidden" name="card_type" value={cardType} style={{display: 'none'}} />

                    {!cardDesign.has_range && denominations.length > 1 &&
                      <React.Fragment>
                        <Typography variant="h6">Select value</Typography>
                        {cardDesign.fee > 0 &&
                          <Typography paragraph>
                            <FormattedNumber value={parseFloat(cardDesign.fee)} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>
                            <FormattedMessage id="order.card_fee" defaultMessage=" (inc {gst}) fee per card applies" values={{gst: 'GST'}}/>
                          </Typography>
                        }
                        {denominations.map((value, index) =>
                          <Button key={index} variant='contained' onClick={() => this.setState({value: value})} style={{margin: '0.5em 1em'}}>
                            <FormattedNumber value={value} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol' maximumFractionDigits={value % 1 === 0 ? '0' : '2'} />
                          </Button>
                        )}
                      </React.Fragment>
                    }
                    <TextInput type='number' name='amount'
                               label={
                                cardDesign.product_type != 'digital_retailer' ?
                                  intl.formatMessage({id: "order.enter_amount", defaultMessage: "Enter Your Own Amount"})
                                :
                                  intl.formatMessage({id: "order.amount", defaultMessage: "Amount"})
                               }
                               required
                               value={this.state.value}
                               disabled={cardDesign.product_type == 'digital_retailer' && !cardDesign.has_range}
                               hint={
                                cardDesign.product_type != 'digital_retailer' ? '' :
                                cardDesign.has_range
                                ? intl.formatMessage({id: "order.enter_amount_between", defaultMessage: "Enter an amount between {min} and {max}" }, { min: currencySign()+parseFloat(minCardValue), max: currencySign()+parseFloat(maxCardValue) })
                                : denominations.length > 1
                                ? intl.formatMessage({id: "order.select_amount", defaultMessage: "Select an amount above"}) : null
                               }
                               onChange={this.updateAmount}
                      inputProps={{
                        startAdornment: <InputAdornment position="start">{currencySign()}</InputAdornment>
                      }}
                      inputFieldProps={{
                        step: '0.01'
                      }}
                      validations={{
                        isGreaterEqualTo: minCardValue,
                        isLessEqualTo: maxCardValue
                      }}
                      validationErrors={Object.assign({}, requiredValidation, {
                        isGreaterEqualTo: intl.formatMessage({id: "order.amount_min", defaultMessage: "must be greater than or equal to"}) + ' ' + currencySign() + parseInt(minCardValue),
                        isLessEqualTo: intl.formatMessage({id: "order.amount_max", defaultMessage: "must be less than or equal to"}) + ' ' + currencySign() + parseInt(maxCardValue)
                      })}
                    />
                    {cardDesign.promotion &&
                      <div style={{display: 'flex'}}>
                        <div style={window.PROMOTION_BACKGROUND_COLOUR.length > 1 ? promotionStyle : {}} >
                          <Typography variant="caption" style={{color: window.PROMOTION_FONT_COLOUR}}>
                            {purchaseDisplay(cardDesign.promotion, this.state.value, intl)}
                          </Typography>
                          {cardDesign.promotion.promotion_type == 'buy_one_get_one_free' &&
                            <React.Fragment>
                              <TextInput name="free_product_id" type="hidden" value={cardDesign.promotion.free_card_design.id} />
                              <TextInput name="free_product_value" type="hidden" value={cardDesign.promotion.value} />
                            </React.Fragment>
                          }
                        </div>
                      </div>
                    }

                    {(cardDesign.product_type == 'physical' || cardDesign.product_type == 'digital_retailer') &&
                      <TextInput type='number' name='quantity'
                                 label={intl.formatMessage({id: "order.quantity", defaultMessage: "Quantity"})}
                                 value='1'
                                 required
                        validations={{
                          isGreaterThan: 0,
                          isLessEqualTo: max_card_quantity
                        }}
                        validationErrors={Object.assign({}, requiredValidation, {
                          isGreaterThan: intl.formatMessage({id: "order.quantity_min", defaultMessage: "must be greater than 0"}),
                          isLessEqualTo: intl.formatMessage({id: "order.quantity_max", defaultMessage: "maximum number of cards is"}) + ' ' + max_card_quantity
                        })}
                      />
                    }

                    {(cardDesign.product_type == 'digital' && cardDesign.product_type != 'digital_retailer') &&
                      <React.Fragment>
                        <TextInput type='number' name='delivery_sms_number'
                                   label={intl.formatMessage({id: "order.delivery_sms", defaultMessage: "Recipient Mobile Number"})}
                                   required
                          hint={intl.formatMessage({id: "order.delivery_sms_hint", defaultMessage: "Mobile number the digital card will be sent to (Australian numbers only). The card cannot be forwarded to another number after delivery"})}
                          validations="isLength:10"
                          validationErrors={_.merge(requiredValidation, {isLength: intl.formatMessage({id: "order.delivery_sms_length", defaultMessage: "must be 10 digits"})})}
                        />

                        <SnackbarContent
                          className="info-container"
                          aria-describedby="info-snackbar"
                          message={
                            <span id="info-snackbar" className="message">
                              <InfoIcon className="icon" />
                              <FormattedMessage id="order.delivery_sms_info" defaultMessage="Please Note: The card cannot be forwarded to another phone number after delivery" />
                            </span>
                          }
                        />

                        <TextInput type='number' name='delivery_sms_number_confirmation'
                                   label={intl.formatMessage({id: "order.delivery_sms_confirmation", defaultMessage: "Recipient Mobile Number Confirmation"})}
                                   required
                          validations="isLength:10,equalsField:delivery_sms_number"
                          validationErrors={_.merge(requiredValidation, {isLength: intl.formatMessage({id: "order.delivery_sms_length", defaultMessage: "must be 10 digits"}),
                                                                         equalsField: intl.formatMessage({id: "order.delivery_sms_mismatch", defaultMessage: "must match recipient number"})
                          })}
                        />

                        <Grid container spacing={16}>
                          <Grid item xs={12} md={6}>
                            <TextInput name="delivery_first_name"
                                       label={intl.formatMessage({id: "order.delivery_first_name", defaultMessage: "Recipient First Name"})}
                                       required
                              validationErrors={requiredValidation}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextInput name="delivery_last_name"
                                       label={intl.formatMessage({id: "order.delivery_last_name", defaultMessage: "Recipient Last Name"})}
                                       required
                              validationErrors={requiredValidation}
                            />
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }

                    {cardDesign.product_type == 'digital_retailer' &&
                      <React.Fragment>
                        <TextInput name="delivery_email"
                          label={intl.formatMessage({id: "order.delivery_email", defaultMessage: "Recipient Email"})}
                          hint={intl.formatMessage({id: "order.delivery_email_hint", defaultMessage: "Email address the digital card will be sent to. If left blank, the card will be sent to the purchaser's email address."})}
                                     delivery_email_hint
                          validations="isEmail"
                          validationErrors={_.merge(requiredValidation, {isEmail: intl.formatMessage({id: "order.delivery_email_invalid", defaultMessage: "must be a valid email address"})})}
                        />

                        <TextInput name="delivery_email_confirmation"
                          label={intl.formatMessage({id: "order.delivery_email_confirmation", defaultMessage: "Recipient Email Confirmation"})}
                          validations="isEmail,equalsField:delivery_email"
                          validationErrors={_.merge(requiredValidation, {isEmail: intl.formatMessage({id: "order.delivery_email_invalid", defaultMessage: "must be a valid email address"}),
                                                                         equalsField: intl.formatMessage({id: "checkout.details.email_mismatch", defaultMessage: "Must match email"})
                          })}
                        />
                      </React.Fragment>
                    }

                    {cardDesign.product_type == 'digital' &&
                      <React.Fragment>
                        <RadioInput name='send_now' label='' row
                          value={sendNow}
                          onChange={(value) => this.setState({sendNow: value})}
                          options={[
                            {label: intl.formatMessage({id: "order.send_now", defaultMessage: "Send Now"}), value: '1'},
                            {label: intl.formatMessage({id: "order.send_later", defaultMessage: "Send Later"}), value: '0'}]}
                        />

                        {sendNow == '0' &&
                          <DateInput name="scheduled_time"
                                     label={intl.formatMessage({id: "order.scheduled_date", defaultMessage: "Scheduled Date"})}
                                     scheduled_date
                                     minDate={moment().add(1, 'days')} maxDate={moment().add(3, 'months').calendar()}
                                     hint={intl.formatMessage({id: "order.scheduled_date_hint",
                                       defaultMessage: "Date to send the card, up to 3 months from now. Cards will be sent at {time} on the date selected." },
                                       {time: moment().hour(digital_card_send_hour).format('ha')})}
                                     required />
                        }
                      </React.Fragment>
                    }

                    <CheckboxInput name="include_message"
                                   label={intl.formatMessage({id: "order.include_message", defaultMessage: "Would you like to include a message?"})}
                      onChange={(v) => this.setState({includeMessage: v === '1'})}
                    />

                    {this.state.includeMessage &&
                      <React.Fragment>
                        {(cardDesign.product_type == 'physical' || cardDesign.product_type == 'digital_retailer') &&
                          <TextInput name='to'
                                     label={intl.formatMessage({id: "order.message_to", defaultMessage: "To"})}
                                     required
                            validationErrors={requiredValidation}
                          />
                        }
                        <TextInput name='from'
                                   label={intl.formatMessage({id: "order.message_from", defaultMessage: "From"})}
                                     message_from
                                   required
                          validationErrors={requiredValidation}
                        />
                        <TextInput name='message'
                                   label={intl.formatMessage({id: "order.message", defaultMessage: "Message"})}
                                   multiline required
                          hint={intl.formatMessage({id: "order.message_hint", defaultMessage: "Inappropriate messages will not be included"})}
                          validationErrors={requiredValidation}
                        />
                      </React.Fragment>
                    }
                  </Grid>
                </Grid>
                <div className='button-container'>
                  <Button type='submit' variant='contained' color='primary' disabled={isProcessing}>
                    <FormattedMessage id="order.add_to_cart" defaultMessage="Add to cart"/>
                  </Button>
                </div>
              </Formsy>
            </div>

            {cardDesign.terms_and_conditions &&
              <div className='terms-and-conditions'>
                <Typography component="span" variant='body1' gutterBottom>
                  <strong>
                    <FormattedMessage id="order.terms" defaultMessage="Terms and Conditions"/>
                  </strong>
                  <Typography component="span" color="textSecondary" style={{marginTop: '1em', marginBottom: '2em'}}>
                    {renderHTML(Autolinker.link(cardDesign.terms_and_conditions))}
                  </Typography>
                </Typography>
              </div>
            }
            {cardDesign.redemption_instructions &&
              <Expandable title={intl.formatMessage({id: "order.redemption_instructions", defaultMessage: "Redemption Instructions"})}>
                <Typography component="span" color="textSecondary" style={{marginTop: '1em', marginBottom: '1em'}}>
                  {renderHTML(Autolinker.link(cardDesign.redemption_instructions))}
                </Typography>
              </Expandable>
            }
          </article>
        </section>

      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    products: state.product.products,
    siteId: state.site.site.id,
    ...state.site.config,
    isProcessing: state.cart.isProcessing
  };
}

const mapDispatchToProps = {
  addToCart: (data) => addToCart(data),
  navigateTo: (path) => push(path)
}

export default withStyles(commonTheme)(connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderCardForm)));
