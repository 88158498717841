import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import _ from 'lodash';
import { InputLabel, Radio, RadioGroup, FormHelperText, FormControlLabel } from '@material-ui/core';
import classnames from 'classnames';

export class RadioInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this)
  }

  handleOnChange(event, value){
    this.props.onChange(value)
  }

  render() {
    const { name, label, options, row, containerClass, hint, getValue, getErrorMessage, isPristine, onChange } = this.props;
    const errorMessage = getErrorMessage();
    const isError = !isPristine() && errorMessage != null;

    return (
      <div className={classnames('input-container', containerClass)}>
        <InputLabel htmlFor={name} error={isError}>{label}</InputLabel>
        <RadioGroup
          id={name}
          row={row}
          style={row && {justifyContent: 'center'}}
          name={name}
          onChange={this.handleOnChange}
          value={getValue() || ""}
        >
          {options.map((option, i) => (
            _.isString(option) ?
              <FormControlLabel key={i} value={option} control={<Radio />} label={option} />
            :
              <FormControlLabel key={i} value={option.value} control={<Radio />} label={option.label} />
          ))}
        </RadioGroup>
        {!isPristine() && errorMessage &&
          <FormHelperText error={isError} className="input-error">{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText>{hint}</FormHelperText>
        }
      </div>
    );
  }
}

RadioInput.propTypes = {
  ...propTypes
};

export default withFormsy(RadioInput);
