import React from 'react';
import Formsy from 'formsy-react';
import _ from 'lodash';
import { Typography, Button, Grid, CircularProgress } from '@material-ui/core';
import CartSummary from 'components/CartSummary';
import { withStyles } from '@material-ui/core/styles';
import RadioInput from 'components/inputs/RadioInput';
import TextInput from 'components/inputs/TextInput';
import CheckboxInput from 'components/inputs/CheckboxInput';
import { Link } from 'react-router-dom';
import { deliveryFee } from 'utils/fees';
import { getCountry } from 'utils/countries';
import renderHTML from 'react-render-html';
import Warning from '@material-ui/icons/Warning';
import {FormattedMessage, injectIntl} from "react-intl";

const styles = theme => ({
  title: {
    fontWeight: 'bold',
    borderBottom: '2px solid black',
    marginBottom: '15px',
    paddingTop: '20px'
  },
  marginTop: {
    marginTop: '20px'
  },
  bold: {
    fontWeight: 'bold'
  },
  warningIcon: {
    marginTop: '5px',
    marginBottom: '-5px'
  }
});

export class ConfirmStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      termsAccepted: '0',
      code: '',
      codeValue: 0
    };

    this.handleStoredCardSubmit = this.handleStoredCardSubmit.bind(this);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { config, formData, handleDeliverySelect } = this.props;

    if (!formData.delivery_option_id) {
      handleDeliverySelect(config.delivery_options[0].id.toString());
    }
  }

  renderSummary(prefix) {
    const { formData } = this.props;

    return (
      <React.Fragment>
        <Typography variant='subtitle1'>{`${formData[`${prefix}_first_name`]} ${formData[`${prefix}_last_name`]}`}</Typography>
        { formData[`${prefix}_company_name`] &&
          <Typography variant='subtitle1'>{formData[`${prefix}_company_name`]}</Typography>
        }
        <Typography variant='subtitle1'>{formData[`${prefix}_street_address`]}</Typography>
        <Typography variant='subtitle1'>{`${formData[`${prefix}_suburb`]} ${formData[`${prefix}_state`]} ${formData[`${prefix}_postcode`]}`}</Typography>
        {formData[`${prefix}_country`] &&
          <Typography variant='subtitle1'>{getCountry(formData[`${prefix}_country`])['name']}</Typography>
        }
      </React.Fragment>
    );
  }

  handleStoredCardSubmit(data) {
    const { validateStoredCard, handleStoredCardSubmit } = this.props;

    validateStoredCard(data, handleStoredCardSubmit, this.formRef);
  }

  termsLink() {
    const customPages = _.flatten(_.map(this.props.site.custom_page_groups, 'custom_pages'));
    const termsPage = _.find(customPages, {template: 'terms_and_conditions'});

    return termsPage ? `/${termsPage.path}` : '/terms_and_conditions';
  }

  render() {
    const { handleBack, handleNext, handleDeliverySelect, formData, site, products, cart, classes, config, digitalOnly, intl } = this.props;
    const requiredValidation= { isDefaultRequiredValue: intl.formatMessage({id: "validation_required", defaultMessage: "can't be blank"})};

    if (!formData.delivery_option_id) {
      return <CircularProgress />;
    }

    return (
      <React.Fragment>
        <CartSummary cart={cart} site={site} products={products}
          deliveryFee={digitalOnly ? 0 : deliveryFee(config.delivery_options, formData.delivery_option_id)} codeValue={this.state.codeValue}
          storedCard={{number: formData.stored_card_number, balance: formData.stored_card_amount}}
        />
        {site.closed_loop_redemptions && !site.closed_loop_checkout_step &&
          <React.Fragment>
            <Typography align='center' variant="h4">
              <FormattedMessage id="checkout.confirm.use_stored_card" defaultMessage="Have a {name} gift card?" values={{name: site.closed_loop_name}}/>
            </Typography>

            <Formsy onValidSubmit={this.handleStoredCardSubmit} className="full-width" ref={this.formRef}>
              <Grid container spacing={8} justify='center' style={{marginBottom: '3em'}}>
                <Grid item md={6} xs={12}>
                  <TextInput name="stored_card_number"
                             label={site.closed_loop_name + ' ' + intl.formatMessage({id: "checkout.confirm.stored_card_number", defaultMessage: "Card Number"})}
                             required
                    validationErrors={requiredValidation}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextInput name="pin"
                             label={intl.formatMessage({id: "checkout.confirm.stored_card_pin", defaultMessage: "PIN"})}
                             required
                    validations="isLength:4"
                    validationErrors={_.merge(requiredValidation, {
                      isLength: intl.formatMessage({id: "checkout.confirm.stored_card_pin_invalid", defaultMessage: "must be 4 digits"})
                    })}
                  />
                </Grid>
                <Grid item md={2} xs={4} align="center" style={{paddingTop: '36px'}}>
                  <Button type='submit' variant='contained'>
                    <FormattedMessage id="checkout.confirm.stored_card_submit" defaultMessage="Submit"/>
                  </Button>
                </Grid>
              </Grid>
            </Formsy>
          </React.Fragment>
        }
        <Formsy onValidSubmit={handleNext} className='full-width'>
          <Grid container spacing={8} justify='center'>
            <Grid item md={10} xs={12}>
              <div className={classes.marginTop}>
                {!digitalOnly &&
                  <RadioInput name='delivery_option_id'
                              label={intl.formatMessage({id: "checkout.confirm.delivery", defaultMessage: "Delivery"})}
                              containerClass='top-space'
                    value={formData.delivery_option_id}
                    onChange={(value) => handleDeliverySelect(value)}
                    options={config.delivery_options.map((option) => {return {value: option.id.toString(), label: `${option.name} $${option.amount}`}})}
                  />
                }
              </div>

              <Typography align="center">
                <FormattedMessage id="checkout.confirm.receipt_email" defaultMessage="Invoice and tracking information to be sent to:"/>
                <span className={classes.bold}> {formData.email}</span>
              </Typography>
              {!window.RISKIFIED_SESSION_ID &&
                <Typography align='center'>
                  <Warning className={classes.warningIcon} />
                  <FormattedMessage id="checkout.confirm.risk_warning" defaultMessage="You may be contacted to verify your identification before your cards are dispatched"/>
                </Typography>
              }
            </Grid>
            {site.checkout_confirmation_text &&
              <Grid item md={10} xs={12}>
                <Typography component="span" color="textPrimary" align="center" className="top-space no-paragraph-space">
                  {renderHTML(site.checkout_confirmation_text)}
                </Typography>
              </Grid>
            }
          </Grid>
          <div className={classes.marginTop}>
            <Grid container spacing={8} justify='center'>
              {!digitalOnly &&
                <Grid item md={5} xs={12}>
                  <Typography variant='subtitle1' className={classes.title}>
                    <FormattedMessage id="checkout.confirm.deliver_to" defaultMessage="DELIVER TO"/>
                  </Typography>
                  {this.renderSummary('recipient')}
                </Grid>
              }
              <Grid item md={5} xs={12}>
                <Typography variant='subtitle1' className={classes.title}>
                  <FormattedMessage id="checkout.confirm.billed_to" defaultMessage="BILLING TO"/>
                </Typography>
                { formData.billing_same_address === '1' ?
                  this.renderSummary('recipient')
                  :
                  this.renderSummary('billing')
                }
              </Grid>
              <Grid item md={10} xs={12}>
                <CheckboxInput name='acceptance' value='0'
                               onChange={(v) => this.setState({termsAccepted: v})}
                               label={<span>
                                  {intl.formatMessage({id: "checkout.confirm.agreement", defaultMessage: "I agree to the"}) + ' '}
                                  <Link to={this.termsLink()} target='_blank'>
                                    <FormattedMessage id="checkout.confirm.terms" defaultMessage="Terms & Conditions"/>
                                  </Link></span>}
                               validations="equals:&quot;1&quot;"
                               validationErrors={{
                                 equals: intl.formatMessage({id: "checkout.confirm.terms_consent_invalid", defaultMessage: "Must tick to continue"})
                               }}
                />
              </Grid>
            </Grid>
          </div>

          <div className='button-container'>
            <Button variant='contained' onClick={handleBack}>
              <FormattedMessage id="checkout.confirm.back" defaultMessage="Back"/>
            </Button>
            <Button variant='contained' color='primary' onClick={handleNext} disabled={this.state.termsAccepted !== '1'}>
              <FormattedMessage id="checkout.confirm.proceed" defaultMessage="Next"/>
            </Button>
          </div>
        </Formsy>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(injectIntl(ConfirmStep));
