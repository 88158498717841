import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Typography, Grid, Button, Divider, InputAdornment, CircularProgress, FormHelperText } from '@material-ui/core';
import TextInput from 'components/inputs/TextInput';
import { currencyCode } from 'utils/locales';
import renderHTML from 'react-render-html';
import ReCAPTCHA from 'react-google-recaptcha';
import { fetchBalance } from 'actions/BalanceActions';
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import _ from 'lodash';

export class CheckBalancePage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCaptchaChange = this.onCaptchaChange.bind(this);
    this.formRef = React.createRef();
    this.recaptchaRef = React.createRef();

    this.state = {
      captchaResponse: null,
      captchaError: null
    }
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    if (this.state.captchaResponse) {
      this.setState({captchaError: null});
      this.props.fetchBalance(_.merge(data,{captcha_response: this.state.captchaResponse}), this.formRef, this.recaptchaRef);
    } else {
      this.setState({captchaError: 'Captcha is required'});
    }
  }

  onCaptchaChange(captchaResponse) {
    this.setState({captchaResponse: captchaResponse, captchaError: null});
  }

  render() {
    const { site, balance, expiryDate, isFetching, intl } = this.props;

    return (
      <React.Fragment>
        <Typography variant='h4' component="h1" align='center'>
          <FormattedMessage id="balance.title" defaultMessage="Check Your Balance"/>
        </Typography>

        {site.balance_page_text &&
          <Typography component="div" variant="body1" align="center">
            {renderHTML(site.balance_page_text)}
          </Typography>
        }

        <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef} className="full-width">
          <Grid container spacing={8} justify="center">
            <Grid item md={6} xs={12}>
              <TextInput name="card_number"
                         label={intl.formatMessage({id: "balance.card_number", defaultMessage: "Card Number"})}
                         required
                hint={intl.formatMessage({id: "balance.card_number_hint", defaultMessage: "Enter your 11 digit Card ID or 19 digit Card Number"})}
                inputProps={{
                  endAdornment: isFetching ? <InputAdornment position="end"><CircularProgress size={20} /></InputAdornment> : null
                }}
                validationErrors={{isDefaultRequiredValue: intl.formatMessage({id: "validation_required", defaultMessage: "can't be blank"})}}
              />
              <ReCAPTCHA
                ref={this.recaptchaRef}
                sitekey={window.CAPTCHA_CLIENT_KEY || ''}
                onChange={this.onCaptchaChange}
                className="g-recaptcha"
                style={{marginTop: '2em'}}
                hl={intl.locale}
              />
              {this.state.captchaError &&
                <FormHelperText error>{this.state.captchaError}</FormHelperText>
              }
            </Grid>
          </Grid>

          <div className='button-container'>
            <Button type='submit' variant='contained' color='primary' disabled={isFetching}>
              <FormattedMessage id="balance.proceed" defaultMessage="Check Balance"/>
            </Button>
          </div>

          {balance &&
            <React.Fragment>
              <Divider style={{marginTop: '2em', marginBottom: '2em'}} />
              <div style={{textAlign: 'center'}}>
                <Typography gutterBottom>
                  <FormattedMessage id="balance.card_balance" defaultMessage="Your card balance is:"/>
                </Typography>
                <Typography color="primary" variant="h4" gutterBottom>
                  <FormattedNumber id="balance" value={balance} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>
                </Typography>

                <Typography gutterBottom>
                  <FormattedMessage id="balance.card_balance_expiry" defaultMessage="Your card expires on:"/>
                </Typography>
                <Typography id="expiry" color="primary" variant="h5" gutterBottom>{expiryDate}</Typography>
              </div>
            </React.Fragment>
          }
        </Formsy>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    site: state.site.site,
    balance: state.balance.balance,
    expiryDate: state.balance.expiryDate,
    isFetching: state.balance.isFetching
  };
}

const mapDispatchToProps = {
  fetchBalance: (data, form, recaptcha) => fetchBalance(data, form, recaptcha)
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CheckBalancePage));
