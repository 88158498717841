import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import { currencyCode } from 'utils/locales';
import { cartTotal, totalSavings, totalBonus } from 'utils/fees';
import moment from 'moment';
import { formatMobileNumber } from 'utils/mobileNumbers';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";

export class CartSummary extends React.Component {
  calculateTotal() {
    const { storedCard } = this.props;

    return this.valueTotal() - (storedCard.balance || 0)
  }

  valueTotal() {
    const { cart, deliveryFee } = this.props;

    return cartTotal(cart) + parseFloat(deliveryFee);
  }

  renderGst() {
    const { cart, deliveryFee } = this.props;
    // GST applied for all card fees and delivery fee
    const gst = (_.reduce(cart, (sum, lineItem) => sum + (lineItem.card_fee * lineItem.quantity), 0) + parseFloat(deliveryFee)) / 11;
    return (
      <FormattedNumber value={gst} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>
    );
  }

  render() {
    const { cart, site, products, deliveryFee, storedCard, removable, handleRemoveFromCart, intl } = this.props;

    return (
      <section className="cart-container grid-outer">
        <article className="cart grid medium">
          <Typography variant="h4" component="h1" paragraph>
            <FormattedMessage id="cart.title" defaultMessage="Shopping Cart"/>
          </Typography>
          <table className="cart-table">
            <thead>
              <tr>
                <th className="product-thumb"><Typography>
                  <FormattedMessage id="cart.item" defaultMessage="Item"/>
                </Typography></th>
                <th className="product-name"></th>
                <th className="product-type">
                  <Typography>
                    <FormattedMessage id="cart.type" defaultMessage="Type"/>
                  </Typography>
                </th>
                <th className="product-qty">
                  <Typography>
                    <FormattedMessage id="cart.quantity" defaultMessage="QTY"/>
                  </Typography>
                </th>
                <th className="product-value">
                  <Typography>
                    <FormattedMessage id="cart.value" defaultMessage="Value"/>
                  </Typography>
                </th>
                <th className="product-fee">
                  <Typography>
                    <FormattedMessage id="cart.card_fee" defaultMessage="Card Fee"/>
                  </Typography>
                </th>
                <th className="product-total-value">
                  <Typography>
                    <FormattedMessage id="cart.total" defaultMessage="Total"/>
                  </Typography>
                </th>
              </tr>
            </thead>
            {products.length > 0 &&
              <React.Fragment>
                <tbody>
                  {cart.map((item, index) =>
                    <React.Fragment key={index}>
                      <tr>
                        <td className="product-thumb">
                          <NavLink to={'/card_designs/' + _.find(products, function(c) { return c.id == item.card_design_id }).slug} className='button-link'>
                            <img src={_.find(products, function(c) { return c.id == item.card_design_id }).image} alt={_.find(products, function(c) { return c.id == item.card_design_id }).name} />
                          </NavLink>
                        </td>
                        <td className="product-name">
                          <Typography>{_.find(products, function(c) { return c.id == item.card_design_id }).name}</Typography>
                          {item.delivery_method == 'sms' &&
                            <Typography className="product-added-detail" style={{fontStyle: 'italic'}}>
                              {intl.formatMessage({id: "cart.deliver_to", defaultMessage: "To"}) + ': ' + formatMobileNumber(item.delivery_sms_number)}
                              <br />
                              {item.scheduled_time && intl.formatMessage({id: "cart.deliver_on", defaultMessage: "On"}) + ': ' + moment(item.scheduled_time).format('ll')}
                            </Typography>
                          }
                          {item.delivery_method == 'email' &&
                            <Typography className="product-added-detail" style={{fontStyle: 'italic'}}>
                              {intl.formatMessage({id: "cart.deliver_to", defaultMessage: "To"}) + ': ' + (item.delivery_email || intl.formatMessage({id: "cart.deliver_to_purchaser_email", defaultMessage: "Purchaser email"}))}
                              <br />
                              {item.scheduled_time && `On: ${moment(item.scheduled_time).format('ll')}`}
                            </Typography>
                          }
                          {item.message &&
                            <Typography className="product-added-detail" style={{fontStyle: 'italic'}}>
                              {intl.formatMessage({id: "cart.deliver_message", defaultMessage: "Message"}) + ': ' + item.message}
                            </Typography>
                          }
                        </td>
                        <td className="product-type"><Typography>{item.card_type}</Typography></td>
                        <td className="product-qty"><Typography>{item.quantity}</Typography></td>
                        <td className="product-value">
                          <Typography>{<FormattedNumber value={parseFloat(item.amount)} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>}</Typography>
                        </td>
                        <td className="product-fee">
                          <Typography>{<FormattedNumber value={parseFloat(item.card_fee)} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>}</Typography>
                        </td>
                        <td className="product-total-value">
                          <Typography>{<FormattedNumber value={parseFloat(item.total) || (item.quantity * (parseFloat(item.amount) + parseFloat(item.card_fee)))} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>}</Typography>
                          {removable && <DeleteIcon className="js-remove-product" onClick={() => handleRemoveFromCart(item.id)} titleAccess='Delete' />}
                        </td>
                      </tr>
                      {item.free_product_id &&
                        <tr>
                          <td className="product-thumb">
                            <img src={_.find(products, function(c) { return c.id == item.free_product_id }).image} alt={_.find(products, function(c) { return c.id == item.free_product_id }).name} />
                          </td>
                          <td className="product-name">
                            <Typography>{_.find(products, function(c) { return c.id == item.free_product_id }).name}</Typography>
                          </td>
                          <td><Typography>{_.find(products, function(c) { return c.id == item.free_product_id }).product_type.replace('_retailer', '')}</Typography></td>
                          <td className="product-qty"><Typography>{item.quantity}</Typography></td>
                          <td className="product-value">
                            <Typography>{<FormattedNumber value={parseFloat(item.free_product_value)} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>}</Typography>
                          </td>
                          <td className="product-fee">
                            <Typography>{<FormattedNumber value={0} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>}</Typography>
                          </td>
                          <td className="product-total-value">
                            <Typography>{<FormattedNumber value={0} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>}</Typography>
                          </td>
                        </tr>
                      }
                    </React.Fragment>
                  )}
                </tbody>
                <tfoot>
                  {totalSavings(cart) > 0 &&
                    <tr>
                      <td colSpan="4"/>
                      <td colSpan="2">
                        <Typography>
                          <FormattedMessage id="cart.savings" defaultMessage="Savings"/>
                        </Typography>
                      </td>
                      <td className="large-text product-total-value">
                        <Typography>
                          {<FormattedNumber value={totalSavings(cart)} style='currency'
                                            currency={currencyCode()} currencyDisplay='narrowSymbol'/>}
                        </Typography>
                      </td>
                    </tr>
                  }
                  {totalBonus(cart) > 0 &&
                    <tr>
                      <td colSpan="4"/>
                      <td colSpan="2">
                        <Typography>
                          <FormattedMessage id="cart.bonus" defaultMessage="Bonus"/>
                        </Typography>
                      </td>
                      <td className="large-text product-total-value">
                        <Typography>
                          {<FormattedNumber value={totalBonus(cart)} style='currency'
                                            currency={currencyCode()} currencyDisplay='narrowSymbol'/>}
                        </Typography>
                      </td>
                    </tr>
                  }
                  {(deliveryFee || cart.filter((item) => item.card_type === 'Physical').length > 0) &&
                    <tr>
                      <td colSpan="4" />
                      <td colSpan="2" className="product-delivery-fee-label">
                        <Typography>
                          <FormattedMessage id="cart.delivery_fee" defaultMessage="Delivery Fee"/>
                        </Typography>
                      </td>
                      <td className="product-delivery-fee-value">
                        <Typography>
                          {deliveryFee ?
                            <FormattedNumber value={parseFloat(deliveryFee)} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>
                          :
                            cart.filter((item) => item.card_type === 'Physical').length > 0 ?
                              intl.formatMessage({id: "cart.calc_during_checkout", defaultMessage: "Calculated during checkout"})
                            :
                              <FormattedNumber value={0} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>
                          }
                        </Typography>
                      </td>
                    </tr>
                  }
                  {parseFloat(storedCard.balance) > 0 &&
                    <tr>
                      <td colSpan="4" />
                      <td colSpan="2">
                        <Typography>
                          <FormattedMessage id="cart.stored_card" defaultMessage="Stored Card"/> {storedCard.number}
                        </Typography>
                      </td>
                      <td>
                        <Typography>-<FormattedNumber value={Math.min(this.valueTotal(), storedCard.balance)} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/></Typography>
                      </td>
                    </tr>
                  }
                  <tr>
                    <td className="product-thumb"></td>
                    <td className="product-name"></td>
                    <td className="product-type"></td>
                    <td className="product-qty"></td>
                    <td colSpan="2" className="large-text product-total-label">
                      <Typography variant="h5">
                        <FormattedMessage id="cart.total" defaultMessage="Total"/>
                      </Typography>
                    </td>
                    <td className="large-text product-total-value">
                      <Typography variant="h5">
                        {<FormattedNumber value={Math.max(this.calculateTotal(), 0)} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>}
                      </Typography>
                    </td>
                  </tr>
                  {window.LOCALE == 'AU' &&
                    <tr className="added-gst-row">
                      <td colSpan="4" />
                      <td colSpan="2" className="product-delivery-fee-label">
                        <Typography>
                          <FormattedMessage id="cart.gst_message" defaultMessage="GST included in total"/>
                        </Typography>
                      </td>
                      <td className="product-delivery-fee-value">
                        <Typography>{this.renderGst()}</Typography>
                      </td>
                    </tr>
                  }
                </tfoot>
              </React.Fragment>
            }
          </table>
        </article>
      </section>
    )
  }
}

CartSummary.defaultProps = {
  deliveryFee: 0,
  storedCard: {}
};

export default injectIntl(CartSummary);
