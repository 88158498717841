import React from 'react';
import { connect } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import { clearCart, removeFromCart } from 'actions/CartActions';
import { push } from 'connected-react-router';
import { Helmet } from "react-helmet";
import CartSummary from 'components/CartSummary';
import { appendSeoBrand } from 'utils/seo';
import { FormattedMessage, injectIntl } from "react-intl";

export class CartPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleRemoveFromCart = this.handleRemoveFromCart.bind(this);
  }

  handleRemoveFromCart(itemId) {
    this.props.removeFromCart(itemId);
  }

  render() {
    const { clearCart, cart, site, products } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{appendSeoBrand('Basket')}</title>
          <meta name="robots" content={window.APP_ENV === 'production' ? 'index' : 'noindex'} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <CartSummary cart={cart} site={site} products={products} removable handleRemoveFromCart={this.handleRemoveFromCart} />
        <div className='button-container'>
          <Button variant='contained' color='primary' onClick={() => this.props.navigateTo('/checkout')} disabled={cart.length === 0}>
            <FormattedMessage id="cart.proceed" defaultMessage="Proceed to Checkout"/>
          </Button>
          <Button variant='contained' color='secondary' onClick={() => this.props.navigateTo('/')}>
            <FormattedMessage id="cart.back_to_store" defaultMessage="Continue Shopping"/>
          </Button>
          <Button variant='contained' onClick={clearCart}>
            <FormattedMessage id="cart.clear" defaultMessage="Clear Cart"/>
          </Button>
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    site: state.site.site,
    products: state.product.products
  };
}

const mapDispatchToProps = {
  clearCart,
  navigateTo: (path) => push(path),
  removeFromCart: (itemId) => removeFromCart(itemId)
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CartPage));
