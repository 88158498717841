import React from 'react';
import { connect } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PromoBanner from 'components/PromoBanner';
import LogoBanner from 'components/LogoBanner';
import InfoBanner from 'components/InfoBanner';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { Switch, Route } from 'react-router-dom';
import { FlashMessage } from 'mui-redux-flash';
import MediaQuery from 'react-responsive';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import { appendSeoBrand } from 'utils/seo';
import { changeLocale } from "../actions/SiteActions";

const styles = theme => ({
  headerImage: {
    height: '4em',
    marginTop: '1em'
  },
  layout: {
    width: '100%',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    marginBottom: '2em',
    padding: '2em 0',
    [theme.breakpoints.down('xs')]: {
      padding: '1em 0',
    },
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 1050,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    // All text except buttons
    // TODO: This is pretty hacky, we may want to go through and be more precise about what we make this colour
    '& *:not(button, button span, .checkout-step svg, .checkout-step circle, .input-error, .thanks-tick, .thanks-tick path, .rccs__cvc, .adyen-checkout__input)': {
      color: window.BODY_FONT_COLOUR
    }
  },
  banner: {
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }
});

export class MainLayout extends React.Component {
  renderSiteBanners(site, classes) {
    return(
      <React.Fragment>
        {site.banner_desktop &&
          <MediaQuery minWidth={948}>
            <div className={classes.banner} style={{'backgroundImage': 'url(' + site.banner_desktop + ')', height: '300px'}}></div>
          </MediaQuery>
        }
        {site.banner_mobile &&
          <MediaQuery maxWidth={948}>
            <div className={classes.banner} style={{'backgroundImage': 'url(' + site.banner_mobile + ')', height: '450px'}}></div>
          </MediaQuery>
        }
      </React.Fragment>
    )
  }

  render() {
    const { site, cart, collection, children, classes, navigateTo, locale, changeLocale } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{appendSeoBrand(site.page_title)}</title>
          <meta name="robots" content={window.APP_ENV === 'production' ? 'index' : 'noindex'} />
          <meta name="description" content={site.page_title}/>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <CssBaseline />
        <Header site={site} cart={cart} locale={locale} changeLocale={changeLocale} navigateTo={navigateTo} />
        {site.header_image &&
          <img src={site.header_image} className={classes.headerImage} alt={site.page_title} />
        }
        <MediaQuery minWidth={948}>
          <LogoBanner />
        </MediaQuery>
        <Switch>
          <Route path="/" exact component={() => site.promo_banners.length > 0 ? <PromoBanner banners={site.promo_banners} /> : this.renderSiteBanners(site, classes)} />
          {collection &&
            <Route path="/collections/:categorySlug" exact component={() => collection.promo_banners.length > 0 ? <PromoBanner banners={collection.promo_banners} /> : <img src={collection.banner_image} className="full-width" /> } />
          }
          <Route component={() => this.renderSiteBanners(site, classes)} />
        </Switch>
        <Switch>
          <Route path="/" exact component={() => <InfoBanner features={site.features} />} />
        </Switch>
        <main className={classes.layout}>
          <FlashMessage />
          {children}
        </main>
        <Footer site={site} />
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    site: state.site.site,
    cart: state.cart.cart,
    collection: state.collection.collection,
    locale: state.locale.locale
  };
}

const mapDispatchToProps = {
  navigateTo: (path) => push(path),
  changeLocale: (locale) => changeLocale(locale)
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MainLayout));
