import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { Helmet } from "react-helmet";
import StoredCardStep from 'components/pages/forms/StoredCardStep';
import DetailsStep from 'components/pages/forms/DetailsStep';
import ConfirmStep from 'components/pages/forms/ConfirmStep';
import PaymentStep from 'components/pages/forms/PaymentStep';
import ResponsiveStepper from 'components/ResponsiveStepper'
import { cartTotal, deliveryFee } from 'utils/fees';
import { appendSeoBrand } from 'utils/seo';
import { submitOrder } from 'actions/OrderActions';
import { validateStoredCard } from 'actions/StoredCardActions';
import { FormattedMessage } from "react-intl";

export class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      formData: {}
    };

    this.renderStep = this.renderStep.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleDetailsSubmit = this.handleDetailsSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeliverySelect = this.handleDeliverySelect.bind(this);
    this.handleCreditCardCallback = this.handleCreditCardCallback.bind(this);
    this.handleStoredCardSubmit = this.handleStoredCardSubmit.bind(this);
    this.handleConfirmSubmit = this.handleConfirmSubmit.bind(this);
    this.prepareOrderForPayment = this.prepareOrderForPayment.bind(this);
    this.handlePolling = this.handlePolling.bind(this);

    this.steps = [{key: 'checkout.details_step', title_en: 'Details'},
                  {key: 'checkout.confirm_step', title_en: 'Confirm'},
                  {key: 'checkout.payment_step', title_en: 'Payment'}];

    // Add stored card step if configured
    if (props.site.closed_loop_checkout_step) {
      this.steps.unshift({key: 'checkout.stored_card_step', title_en: 'Checkout'});
    }
  }

  componentDidMount() {
    if (this.props.cart.length === 0) {
      this.props.navigateTo('/')
    }
  }

  renderStep(step) {
    const { cart, site, products, config } = this.props;
    const { formData } = this.state;
    const digitalOnly = _.every(cart, ['card_type', 'Digital']);

    switch (step) {
    case 'checkout.stored_card_step':
        return (
          <StoredCardStep
            handleNext={this.handleNext}
            handleNextClear={this.handleDetailsSubmit}
            validateStoredCard={this.props.validateStoredCard}
            handleStoredCardSubmit={this.handleStoredCardSubmit}
            formData={formData}
            cart={cart}
            site={site}
          />
        );
      case 'checkout.details_step':
        return (
          <DetailsStep
            handleNext={this.handleDetailsSubmit}
            handleBack={this.handleBack}
            formData={formData}
            site={site}
            digitalOnly={digitalOnly}
          />
        );
      case 'checkout.confirm_step':
        return (
          <ConfirmStep
            handleNext={this.handleConfirmSubmit}
            handleBack={this.handleBack}
            handleDeliverySelect={this.handleDeliverySelect}
            validateStoredCard={this.props.validateStoredCard}
            handleStoredCardSubmit={this.handleStoredCardSubmit}
            formData={formData}
            cart={cart}
            site={site}
            products={products}
            config={config}
            digitalOnly={digitalOnly}
          />
        );
      case 'checkout.payment_step':
        return (
          <PaymentStep
            handleNext={this.handleSubmit}
            handleBack={this.handleBack}
            handleNewPayment={this.prepareOrderForPayment}
            handlePolling={this.handlePolling}
            cart={cart}
            site={site}
            config={config}
            digitalOnly={digitalOnly}
            deliveryOptionId={formData.delivery_option_id}
            storedCardAmount={formData.stored_card_amount}
            creditCardIssuer={formData.credit_card_issuer}
            handleCreditCardCallback={this.handleCreditCardCallback}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }

  handleNext() {
    const { activeStep } = this.state;

    this.setState({ activeStep: activeStep + 1 });
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  handleBack() {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  handleDetailsSubmit(data) {
    this.setState({formData: _.merge(this.state.formData, data)});
    this.handleNext();
  }

  handleConfirmSubmit() {
    if (!this.paymentStepRequired()) {
      this.handleSubmit({});
      return;
    }

    if (!window.FONIX_ENABLED && window.CC_GATEWAY == 'adyen') {
      this.prepareOrderForPayment();
    }

    this.handleNext();
  }

  // Some payment methods e.g. Adyen need to save order, and get config to present for payment
  prepareOrderForPayment() {
    const { submitOrder } = this.props;
    const { formData } = this.state;

    this.setState({ formData: _.merge(formData, {payment_method: 'credit_card'})});
    submitOrder(this.mapInputs(formData), {});
  }

  handleStoredCardSubmit(data) {
    this.setState({formData: _.merge(this.state.formData, data)});
  }

  handlePolling() {
    const { order_reference_number } = this.props;

    this.props.navigateTo(`/${order_reference_number}/result`)
  }

  paymentStepRequired() {
    const { cart, config } = this.props;
    const { formData } = this.state;

    if (!formData.stored_card_amount) {
      return true;
    }

    const digitalOnly = _.every(cart, ['card_type', 'Digital']);
    const deliveryAmount = digitalOnly ? 0 : deliveryFee(config.delivery_options, formData.delivery_option_id);
    const cartAmount = cartTotal(cart);

    return formData.stored_card_amount < (cartAmount + parseFloat(deliveryAmount));
  }

  mapInputDetails(prefix, inputs) {
    return {
      company_name: inputs[prefix + '_company_name'],
      first_name: inputs[prefix + '_first_name'],
      last_name: inputs[prefix + '_last_name'],
      street_address: inputs[prefix + '_street_address'],
      suburb: inputs[prefix + '_suburb'],
      state: inputs[prefix + '_state'],
      postcode: inputs[prefix + '_postcode'],
      country: inputs[prefix + '_country']
    }
  }

  mapInputs(inputs) {
    var mappedInputs = {
      'site_id': this.props.site.id,
      'recipient_address_attributes': {
        ...this.mapInputDetails('recipient', inputs)
      },
      'billing_address_attributes': {
        ...this.mapInputDetails('billing', inputs)
      },
      customer_locale: this.props.locale,
      // Riskified session ID
      session_identifier: window.RISKIFIED_SESSION_ID,
      ..._.pick(inputs, ['email', 'phone_number', 'delivery_option_id', 'billing_same_address', 'source', 'marketing_consent',
                         'stored_card_number', 'stored_card_amount', 'stored_card_pin',
                         'payment_method', 'credit_card_issuer', 'credit_card_name', 'credit_card_expiry_month', 'credit_card_expiry_year'])
    };
    if (window.CC_GATEWAY == 'eway') {
      // Encrypt credit card numbers using eWay encryption library
      mappedInputs.credit_card_number = eCrypt.encryptValue(inputs['credit_card_number'], window.EWAY_ENCRYPTION_KEY);
      mappedInputs.credit_card_security_code = eCrypt.encryptValue(inputs['credit_card_security_code'], window.EWAY_ENCRYPTION_KEY);
    }
    return mappedInputs;
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    const { submitOrder, cart } = this.props;
    const { formData } = this.state;

    this.setState({ formData: _.merge(formData, data) });
    submitOrder(this.mapInputs(formData), cart);
  }

  handleDeliverySelect(option) {
    this.setState({formData: _.merge(this.state.formData, {delivery_option_id: option})});
  }

  handleCreditCardCallback(card) {
    this.setState({formData: _.merge(this.state.formData, {credit_card_issuer: card.issuer})});
  }

  render() {
    const { activeStep } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>{appendSeoBrand('Checkout')}</title>
          <meta name="robots" content='noindex' />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Typography variant='h4'>
          <FormattedMessage id="checkout.title" defaultMessage="Checkout"/>
        </Typography>
        <ResponsiveStepper activeStep={activeStep} steps={this.steps} />
        {this.renderStep(this.steps[activeStep].key)}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    site: state.site.site,
    products: state.product.products,
    config: state.site.config,
    locale: state.locale.locale,
    order_reference_number: state.order.order_reference_number
  };
}

const mapDispatchToProps = {
  submitOrder: (data, cart) => submitOrder(data, cart),
  navigateTo: (path) => push(path),
  validateStoredCard: (data, callback, form) => validateStoredCard(data, callback, form),
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
