import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from "react-redux";
import English from 'locales/en.json';
import Dutch from 'locales/nl.json';
import French from 'locales/fr.json';

export function currencyCode() {
  switch (window.LOCALE.slice(-2)) {
  case 'AU':
    return 'AUD';
  case 'GB':
    return 'GBP';
  case 'BE':
    return 'EUR';
  default:
    return 'AUD';
  }
}

export function currencySign() {
  switch (window.LOCALE.slice(-2)) {
  case 'AU':
    return '$';
  case 'GB':
    return '£';
  case 'BE':
    return '€';
  default:
    return '$';
  }
}

export function localeGlobal() {
  // TODO: Maybe we want to add this to site setup to be a bit more flexible eventually
  switch (window.LOCALE.slice(-2)) {
  case 'BE':
    return false;
  case 'GB':
    return false;
  default:
    return false;
  }
}

export const supportedLocales = ['en','fr','nl']

export function availableLocales(localeList) {
  return supportedLocales.filter(value => localeList.includes(value)).map(locale => {
    return { locale: locale, title: localeTitle(locale) };
  })
}

export function localeTitle(locale) {
  switch (locale) {
    case 'nl':
      return 'locale_dutch';
    case 'fr':
      return 'locale_french';
    default:
      return 'locale_english';
  }
}

export function selectMessages(locale) {
  switch (locale) {
    case 'nl':
      return Dutch;
    case 'fr':
      return French;
    default:
      return English;
  }
}

export class I18n extends React.Component {
  render() {
    const { locale } = this.props;

    return (
      <IntlProvider messages={selectMessages(locale)} locale={locale}>
        {this.props.children}
      </IntlProvider>
    );
  };
}


function mapStateToProps(state) {
  return {
    locale: state.locale.locale
  };
}

export default connect(mapStateToProps)(I18n);
