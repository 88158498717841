import React from 'react';
import { Grid, Typography, Button, Table, TableRow, TableFooter, TablePagination, InputAdornment } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Formsy from 'formsy-react';
import CardDesign from 'components/CardDesign';
import TextInput from 'components/inputs/TextInput';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import * as qs from 'query-string';

export class CardGallery extends React.Component {
  constructor(props) {
    super(props);

    const params = qs.parse(location.search);

    this.state = {
      page: parseInt(params['page']) || 0,
      search: params['search'] || ''
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
  }

  handleChangePage(event, newpage) {
    this.setState({page: newpage});
  }

  handleChangeSearch(data) {
    this.setState({search: data.product_search})
    this.setState({page: 0});
  }

  render() {
    const { site, cardDesigns, filter, intl } = this.props;
    const alternativeLink = filter == 'physical' ? 'digital' : 'physical';
    const capitalizedFilter = filter ? filter.charAt(0).toUpperCase() + filter.slice(1) : null;
    const rowsPerPage = site.card_gallery_template == 'grid' ? 8 : 12;

    var params = {
      page: this.state.page
    }
    if (this.state.search) {
      params.search = this.state.search
    }
    window.history.replaceState(null, null, `${location.pathname}?${qs.stringify(params)}`);

    var products = cardDesigns;
    if (this.state.search) {
      products = _.filter(cardDesigns, (p) => { return p.name.toLowerCase().includes(this.state.search.toLowerCase()) });
    }

    return (
      <React.Fragment>
        {filter && <Typography variant="h5" align="center" gutterBottom>{capitalizedFilter} Gift Cards</Typography>}
        <Grid container spacing={16} justify={products.length <= 4 ? 'center' : 'flex-start'} align='center'>
          {cardDesigns.length > 20 &&
            <Grid item xs={12} align="left">
              <Formsy onChange={this.handleChangeSearch}>
                <TextInput type="search" name="product_search" value={this.state.search} label={intl.formatMessage({id: "order.search", defaultMessage: "Search"})} inlineLabel
                  inputProps={{
                    endAdornment: <InputAdornment position="end"><SearchIcon titleAccess='Search' /></InputAdornment>
                  }}
                />
              </Formsy>
            </Grid>
          }
          {products.slice(this.state.page * rowsPerPage, this.state.page * rowsPerPage + rowsPerPage).map((cardDesign, index) =>
            <Grid item key={index} xs={6} md={site.card_gallery_template == 'open' ? 6 : 3}>
               <CardDesign template={site.card_gallery_template} cardDesign={cardDesign} filter={filter}/>
            </Grid>
          )}
        </Grid>
        {(site.card_gallery_template == 'grid' || cardDesigns.length > rowsPerPage) &&
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            count={products.length}
            rowsPerPage={rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
          />
        }
        {filter &&
          <NavLink to={'/' + alternativeLink}>
            <Typography color="inherit" align="center" className="top-space">
              {filter === 'physical' &&
                <FormattedMessage id="landing.alternatives_digital" defaultMessage="Looking for digital cards?"/>
              }
              {filter === 'digital' &&
                <FormattedMessage id="landing.alternatives_physical" defaultMessage="Looking for physical cards?"/>
              }
            </Typography>
          </NavLink>
        }
      </React.Fragment>
    );
  }
}

export default injectIntl(CardGallery);
