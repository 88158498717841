import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { NavLink } from "react-router-dom";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import { galleryBadgeText } from 'utils/promotions';
import { currencyCode } from 'utils/locales';

const promotionStyle = {
  color: window.PROMOTION_FONT_COLOUR,
  background: window.PROMOTION_BACKGROUND_COLOUR,
  paddingLeft: '0.8em',
  paddingRight: '0.8em',
  paddingTop: '0.25em',
  paddingBottom: '0.25em',
  borderStyle: 'solid',
  borderRadius: '10px',
  borderWidth: '1px',
  borderColor: window.PROMOTION_FONT_COLOUR
};

export class CardDesign extends React.Component {
  render() {
    const { template, cardDesign, filter, intl } = this.props;

    return (
      <div className={`gallery-item-${template}`}>
        <div style={{position: 'relative'}}>
          <NavLink to={'/card_designs/' + cardDesign.slug} className='button-link'>
            <img src={cardDesign.image} alt={cardDesign.name} />
            {cardDesign.promotion &&
              <div style={{position: 'absolute', top: '-10px', right: '-10px'}}>
                <div style={window.PROMOTION_BACKGROUND_COLOUR.length > 1 ? promotionStyle : {}}>
                  <Typography variant="caption" style={{color: window.PROMOTION_FONT_COLOUR, fontWeight: 'bold'}}>
                    {galleryBadgeText(cardDesign.promotion)}
                  </Typography>
                </div>
              </div>
            }
          </NavLink>
          {template == 'open' &&
            <Typography variant="subtitle1" gutterBottom>{cardDesign.name}</Typography>
          }
          {template == 'grid' &&
            <Typography variant="h6" align="left" className="top-space bottom-space">{cardDesign.name}</Typography>
          }
        </div>
        <div className={`gallery-item-bottom-${template}`}>
          {template == 'grid' &&
            <Typography variant="h6" align="left">
              {intl.formatNumber(cardDesign.min_amount, {
                style: 'currency', currency: currencyCode(), currencyDisplay: 'narrowSymbol',
                maximumFractionDigits: cardDesign.min_amount % 1 === 0 ? '0' : '2'
              })}
              {cardDesign.min_amount !== cardDesign.max_amount ?
                ' - ' +
                intl.formatNumber(cardDesign.max_amount, {
                  style: "currency", currency: currencyCode(), currencyDisplay: 'narrowSymbol',
                  maximumFractionDigits: cardDesign.max_amount % 1 === 0 ? '0' : '2'
                })
                : ''}
            </Typography>
          }
          {((cardDesign.fee && cardDesign.fee > 0) || (cardDesign.digital_fee && cardDesign.digital_fee > 0)) &&
            <Typography variant="caption" color="textSecondary" paragraph>
              <FormattedNumber value={parseFloat(cardDesign.fee || cardDesign.digital_fee)} style='currency'
                               currency={currencyCode()} currencyDisplay='narrowSymbol'/>
              <FormattedMessage id="order.card_fee" defaultMessage=" (inc {gst}) fee per card applies"
                                values={{gst: 'GST'}}/>
            </Typography>
          }

          <NavLink to={{pathname: '/card_designs/' + cardDesign.slug}} className='button-link'>
            <Button variant='contained' size='medium' color='primary' className='top-space'>
              <FormattedMessage id="navigation.buy_now" defaultMessage="Buy Now"/>
            </Button>
          </NavLink>
        </div>
      </div>
    )
  }
}

export default injectIntl(CardDesign);
